<template>
    <div class="layout-header">
      <div class="contain">
        <div class="logo">
          <img :src="require('@/assets/logo.jpg')" alt="">
        </div>
        <div class="menu">
          <div 
            :class="`item ${item.active?'active':''}`" 
            v-for="item in menu" 
            :key="item.name"
            @click="handleClickmenu(item)"
          >{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="main-contain-wrap">
      <router-view></router-view>
    </div>
    <div class="layout-footer">Mob:+(86) 15888024548 &nbsp;&nbsp; Mail:lilyxu@yiningco.com</div>
</template>

<script setup>
import {defineProps,onMounted,ref,onActivated} from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const menu = ref([
  {
    name:'Home',
    active:false,
    url:'/'
  },
  {
    name:'Product',
    active:false,
    url:'/productList'
  },
  {
    name:'Factory',
    active:false,
    url:'/factory'
  },
  {
    name:'About us',
    active:false,
    url:'/aboutUs'
  },
  {
    name:'Contact us',
    active:false,
    url:'/cantactUs'
  },
])

const handleClickmenu = (data)=>{
  menu.value.forEach((item)=>{
    if(data.name===item.name){
      item.active = true;
    }else{
      item.active = false;
    }
  });

  router.push({
    path:data.url
  })

}

onMounted(()=>{
  menu.value.forEach((item)=>{
    if(window.location.pathname===item.url){
      item.active = true;
    }else{
      item.active = false;
    }
  });
})
</script>
<style lang="less">
  .layout-header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: auto;
    padding: 0 30px;
    background: #fff;
    .contain{
        padding: 10px 5px;
        margin-right: auto;
        margin-left: auto;
        width: 1200px;
        display: flex;
        align-items: center;
        .logo{
          width: 216px;
          img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .menu{
          display: flex;
          margin-left: 50px;
          .item{
            padding: 20px 20px;
            margin: 0 20px;
            cursor: pointer;
            &:hover{
              color: #195dab !important;
              border-bottom: 4px solid #195dab;
            }
            &.active{
              color: #195dab !important;
              border-bottom: 4px solid #195dab;
            }
          }
        }
    }
  }
  .main-contain-wrap{
    padding-top: 86px;
    padding-bottom: 40px;
  }
  .layout-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #272727;
    height: 40px;
    z-index: 10;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    color: #666;
  }
</style>
