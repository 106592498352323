import { createRouter, createWebHistory } from 'vue-router'
const Index = ()=> import('./pages')
const ProductList = ()=>import('./pages/productList')
const AboutUs = ()=>import('./pages/aboutUs')
const ConcactUs = ()=>import('./pages/concactUs')
const Factory = ()=>import('./pages/factory')


const routes = [  
    {   
        path: '/',    
        component: Index,  
    }, 
    {   
        path: '/index',    
        name: 'index',  
        component: Index,  
    }, 
    {   
        path: '/productList',    
        name: 'productList',  
        component: ProductList  
    },
    {   
        path: '/aboutUs',    
        name: 'aboutUs',  
        component: AboutUs  
    },
    {   
        path: '/cantactUs',    
        name: 'ConcactUs',  
        component: ConcactUs  
    },
    {   
        path: '/factory',    
        name: 'Factory',  
        component: Factory  
    },
    
    
]
const router = createRouter({  
    history: createWebHistory(),  
    routes
})

export default router