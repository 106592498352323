<template>
  <div>
    <Layout/>
  </div>
</template>

<script>
import Layout from "@/components/layout.vue"
import './style/index.less'
import './style/swiper-bundle.min.css'

export default {
  name: 'App',
  components:{
    Layout
  }
}
</script>

<style>

</style>
